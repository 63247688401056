
import { useStore } from "vuex"
import { toRefs } from "vue"
import { useRouter } from "vue-router"
import { Dialog } from "vant"

import { UserState } from "@/interface/userModel"

interface OperationItem {
  icon: string,
  title: string
}

export default {
  name: "Mine",
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  setup () {
    const {
      items,
      user,
      defaultHeader,
      itemClick
    } = userActionEffect()

    return {
      items,
      user,
      defaultHeader,
      itemClick
    }
  }
}

const userActionEffect = () => {
  const store = useStore<UserState>()
  const router = useRouter()
  const { user } = toRefs(store.state)
  const defaultHeader = require("@/assets/pic_mine_default_avatar.png")
  const items: OperationItem[] = [
    // {
    //   icon: require("@/assets/icon_mine_channel.png"),
    //   title: "所属渠道"
    // },
    {
      icon: require("@/assets/icon_mine_password.png"),
      title: "修改密码"
    },
    {
      icon: require("@/assets/icon_mine_signout.png"),
      title: "退出登录"
    }
  ]
  const itemClick = (index: number) => {
    switch (index) {
      // case 0:
      //   break
      case 0:
        router.push({ name: "modifyPassword" })
        break
      case 1:
        logout()
        break
    }
  }

  const logout = () => {
    Dialog.confirm({
      title: "退出登录",
      message: "确定要退出登录吗？"
    }).then(() => {
      store.commit("logout")
      router.replace({ name: "login" })
    })
  }

  return {
    items,
    user,
    defaultHeader,
    itemClick
  }
}

